import { Status, TOption } from '@intellecteu/common-ui';
import { NetworkNodeTypeStats, NetworkRequestDTO, NetworkResponseDTO, NodeRequestDTO, NodeResponseDTO } from 'data';
import { TStatusConfig } from 'app/constants/status';

export const testRpcConfigUrl = window._env_?.TEST_RPC_OPENID_CONF_URL || import.meta.env.VITE_TEST_RPC_OPENID_CONF_URL;

export const authTypesOptions: TOption[] = [
  {
    label: 'Disabled',
    value: NetworkRequestDTO.rpcAuthStrategy.NONE,
  },
  {
    label: 'OpenID',
    value: NetworkRequestDTO.rpcAuthStrategy.OPENID,
  },
  {
    label: 'Credentials',
    value: NetworkRequestDTO.rpcAuthStrategy.CREDENTIALS,
  },
];

export const networkStatusConfig: TStatusConfig = {
  [NetworkResponseDTO.state.INITIALIZING]: {
    mode: Status.mode.processing,
    label: 'Initializing',
  },
  [NetworkResponseDTO.state.INITSUCCEEDED]: {
    mode: Status.mode.success,
    label: 'Init succeeded',
  },
  [NetworkResponseDTO.state.INITFAILED]: {
    mode: Status.mode.error,
    label: 'Init failed',
  },
  [NetworkResponseDTO.state.QUORUMALIVE]: {
    mode: Status.mode.success,
    label: 'Quorum alive',
  },
  [NetworkResponseDTO.state.QUORUMFAILED]: {
    mode: Status.mode.error,
    label: 'Quorum failed',
  },
};

export const getNodeStatistics = (nodeTypeStats?: Record<NodeRequestDTO.nodeType, NetworkNodeTypeStats>) => {
  const getAvailableNodes = (nodeType: NodeResponseDTO.nodeType) => nodeTypeStats && nodeTypeStats[nodeType]?.available;
  const getTotalNodes = (nodeType: NodeResponseDTO.nodeType) => nodeTypeStats && nodeTypeStats[nodeType]?.total;

  const availableValidators = getAvailableNodes(NodeResponseDTO.nodeType.VALIDATOR);
  const availableBootnodes = getAvailableNodes(NodeResponseDTO.nodeType.BOOT);
  const availableRPCNodes = getAvailableNodes(NodeResponseDTO.nodeType.RPC);

  const totalValidators = getTotalNodes(NodeResponseDTO.nodeType.VALIDATOR);
  const totalBootnodes = getTotalNodes(NodeResponseDTO.nodeType.BOOT);
  const totalRPCNodes = getTotalNodes(NodeResponseDTO.nodeType.RPC);

  const hasValidatorStats = availableValidators != null && totalValidators != null;
  const hasBootStats = availableBootnodes != null && totalBootnodes != null;
  const hasRPCStats = availableRPCNodes != null && totalRPCNodes != null;

  return {
    availableValidators,
    availableBootnodes,
    availableRPCNodes,
    totalValidators,
    totalBootnodes,
    totalRPCNodes,
    hasValidatorStats,
    hasBootStats,
    hasRPCStats,
  };
};
